// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css'

import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import React from 'react'
import Seo from '../components/Seo'
import parse from 'html-react-parser'

const classes = /** class={ */ {
  article: 'max-w-7xl mx-auto px-2 lg:px-8 py-16 md:py-24 lg:py-32',
  header: 'pb-8 md:pb-12 lg:pb-16',
  title: 'text-2xl md:text-3xl lg:text-4xl',
  content: 'prose prose-pink',
  nav: 'max-w-7xl mx-auto px-2 lg:px-8 py-8',
  links: 'flex flex-wrap justify-between list-none p-0',
  link: 'font-display text-lg',
} /** } */

const BlogPostTemplate = ({ data: { previous, next, post }, pageContext: { basePath } }) => {
  const metaDesc = post.excerpt.replace('<p>', '').replace('</p>', '')
  return (
    <Layout>
      <Seo title={post.title} description={metaDesc} isBlogPost />
      <article className={classes.article}>
        <header className={classes.header}>
          <h1 className={classes.title}>{parse(post.title)}</h1>
          <small>{post.date}</small>
        </header>

        {!!post.content && <section className={classes.content}>{parse(post.content)}</section>}
      </article>
      <nav className={classes.nav}>
        <ul className={classes.links}>
          <li>
            {previous && (
              <Link className={classes.link} to={basePath + previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link className={classes.link} to={basePath + next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
